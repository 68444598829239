<template>
  <v-footer app fixed :color="color" class="footer pa-0" style="z-index: 10;" :height="getHeight">
    <v-row>
      <v-col cols="12" class="text-center" v-html="footer"></v-col>
    </v-row>
  </v-footer>
</template>

<script>
  import {mapState} from "vuex";
  import remoteApi from "../services/remoteApi"

  export default {
    name: "BasicFooter",
    props: {color: {type: String, default: 'grey lighten-4'}},
    data() {
      return {
        footer: '© DAM United AG 2013-2020'
      }
    },
    beforeCreate() {
      remoteApi.loadFooter(this.$i18n.locale).then(data => {
        this.footer = data.htmlBody
      })
    },
    computed: {
      ...mapState({
        config: state => state.config.server
      }),
      getHeight(){
        if(this.config.portalUIConfiguration){
          return this.config.portalUIConfiguration.footerHeight
        }
        return 50
      }
    }
  }
</script>

<style scoped>
  .footer {
    padding: 6px;
  }
</style>
