<template>
  <v-dialog v-model="visible" max-width="600px" @keydown.esc="close">
    <v-card v-if="assetLinks">
      <v-card-title class="text-h5">{{ $t('imagerDialogTitle') }}</v-card-title>
      <v-card-text>
        <v-select :items="assetLinks" v-model="selectedAssetLink" item-text="presetName" return-object/>
        <v-text-field ref="urlField" readonly v-model="selectedAssetLink.encryptedUrlString"></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="primary" :href="url" target="_blank">{{ $t('imagerPreviewImageInTab') }}</v-btn>
        <v-btn text color="primary" @click="copyToClipboard()">{{ $t('imagerCopyImageLink') }}</v-btn>
        <v-btn text color="primary" @click="close()">{{ $t('imagerCloseDialog') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapActions, mapState} from "vuex"
import remoteApi from '../../services/remoteApi'

export default {
  name: "ImagerUrlDialog",
  data() {
    return {
      visible: true,
      assetLinks: null,
      selectedAssetLink: {}
    }
  },
  methods: {
    ...mapActions({'pushError':'notification/pushErrorNotification'}),
    close() {
      this.$router.push({name: 'Home'})
    },
    copyToClipboard() {
      let textToCopy = this.$refs.urlField.$el.querySelector('input')
      textToCopy.select()
      document.execCommand("copy")
      window.getSelection().removeAllRanges()
    },
  },
  created() {
    remoteApi.getImagerPresetLinks(this.assetItemIdentifier).then(value => {
      this.assetLinks = this.uiConfig.allowedImagerPresets ? value.filter(link => this.uiConfig.allowedImagerPresets.indexOf(link.presetId) !== -1) : value
      this.selectedAssetLink = this.assetLinks[0]

      if (this.uiConfig.enableImagerOriginalUrl) {
        remoteApi.getImagerOriginalUrls(this.assetItemIdentifier).then(value => {
          if (value && value.length === 1) {
            value[0].presetName = this.$t('imagerOriginalTitle')
            this.assetLinks.push(value[0])
          }
        }).catch(() => {
          this.pushError(this.$t('imagerServiceError'))
          this.close()
        })
      }
    }).catch(() => {
      this.pushError(this.$t('imagerServiceError'))
      this.close()
    })
  },
  computed: {
    ...mapState({
      assets: state => state.assets.assets,
      uiConfig: state => state.config.server.portalUIConfiguration
    }),
    assetItemIdentifier() {
      try {
        let assetItem = this.assets.find(asset => asset.id === this.$route.params.assetId)
        return assetItem.entity.entityProperties.find(property => property.identifier === "ASSET_ID").value
      } catch (e) {
        return "ID_NOT_AVAILABLE"
      }
    },
    url(){
      if(this.selectedAssetLink){
        return this.selectedAssetLink.encryptedUrlString
      }
      return ""
    }
  },
  watch: {
    visible(val) {
      //Close on Backdrop click/toich
      if (val === false) {
        this.close()
      }
    }
  },
}
</script>

<style scoped>

</style>