import axios from 'axios';
import {store} from '../store/store'
import Url from '../utils/Url'

let axiosInstance = axios.create({
  headers: {
    'Content-Type': 'application/json'
  }
});

axiosInstance.interceptors.response.use(function (response) {
  return response
}, function (error) {
  if (401 === error.response.status) {
    window.location.href = '/' + store.state.main.portal
    return Promise.resolve('')
  }
  return Promise.reject(error)
})

function loadDataByLocale(endpoint, locale) {
  let url = endpoint + locale
  return getData(url)
}

function getData(endpoint) {
  return new Promise((resolve, reject) => {
    axiosInstance.get(endpoint)
      .then(response => {
        resolve(response.data)
      }, error => {
        reject(error)
      })
  })
}

function postData(endpoint, data) {
  return new Promise((resolve, reject) => {
    axiosInstance.post(endpoint, data)
      .then(response => {
        resolve(response.data)
      }, error => {
        reject(error)
      })
  })
}

function getUrl(endpoint, identifier) {
  const baseUrl = store.state.main.baseUrl
  const portal = store.state.main.portal

  return new Url.Builder(baseUrl)
    .addElement(endpoint)
    .addElement(portal)
    .addElement(identifier)
    .build()

}

function executeDownload(endpoint, identifier) {
  let isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
  const url = getUrl(endpoint, identifier)
  if (isIE11) {
    window.location = url.urlString
  } else {
    const a = document.createElement('a')
    a.href = url.urlString
    a.click()
    a.remove()
  }
}

export default {
  async getBackend() {
    //This call is not using the axiosInstance, because it is executed before the axiosInstance is created!
    return axios.get('/server.json')
  },

  init(baseUrl, portal) {
    if (baseUrl !== '') {
      axiosInstance.defaults.baseURL = baseUrl
    }
    axiosInstance.defaults.headers.common['portal'] = portal
  },

  setToken(token) {
    axiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + token
  },

  setRefreshToken(token) {
    axiosInstance.defaults.headers.common['token'] = token
  },

  authenticate(credentials) {
    this.clearToken()
    return axiosInstance.post('/auth', credentials)
  },

  refreshToken() {
    return axiosInstance.post('/refresh')
  },

  clearToken() {
    delete axiosInstance.defaults.headers.common['Authorization']
  },

  loadBaseSettings(locale) {
    return getData('/api/' + store.state.main.portal + '/baseConfig/' + locale)
  },

  loadExtendedSettings() {
    return getData('/api/extendedConfig')
  },

  loadAssets(container, page, locale) {
    return new Promise((resolve, reject) => {
      //TODO Make the pageSize portal configurable!
      axiosInstance.post('/api/containerAssets', {
        page: page,
        pageSize: 30,
        containerNavigationIdentifier: container.id,
        locale: locale
      })
        .then(response => {
          resolve(response.data)
        }, error => {
          reject(error)
        })
    })
  },

  loadAsset(assetId, locale) {
    return postData('/api/asset/', {assetId: assetId, locale: locale})
  },

  loadAssetContainers(parent, page, locale) {
    let data = {containerNavigationIdentifier: parent, page: page, pageSize: 200, locale: locale}
    return postData('/api/assetContainers', data)
  },

  loadAssetFilters(locale) {
    return postData('/api/assetFilters', {locale: locale})
  },

  loadTermsOfUse(locale) {
    return loadDataByLocale('/api/tou/', locale)
  },

  loadFooter(locale) {
    return loadDataByLocale('/api/footer/', locale)
  },

  loadLoginText(locale) {
    return loadDataByLocale('/api/loginText/', locale)
  },

  loadLanguage(locale) {
    return loadDataByLocale('/api/translation/', locale)
  },

  loadDownloadFormats(locale, assetIds) {
    return postData('/api/downloadFormats', {locale: locale, assetIds: assetIds})
  },

  prepareBasketDownload(downloadAssetsIds) {
    return postData("/api/prepareZipDownload", {downloads: downloadAssetsIds})
  },

  prepareMailDownload(locale, data, downloadAssetsIds) {
    return postData("/api/prepareMailDownload", {
      locale: locale,
      email: data.email,
      info: data.info,
      downloads: downloadAssetsIds
    })
  },

  createPortalDownload(locale, model, downloadAssetsIds) {
    let formats = model.downloadFormat.join()
    let downloads = downloadAssetsIds.reduce((map, obj) => {
      map[""+obj] = formats;
      return map
    }, {})
    return postData("/api/createPortalDownload", {
      locale: locale,
      fromDate: model.fromDate,
      toDate: model.toDate,
      name: model.name,
      downloads: downloads
    })
  },

  getVideoPreviewUrl(assetId) {
    return getData("/api/asset/" + assetId + "/videoPreview")
  }
  ,

  assetSearch(searchQuery) {
    return postData("/api/asset/search", searchQuery)
  }
  ,

  executeDirectDownload(asset) {
    executeDownload("download/asset", asset.id)
  }
  ,

  executeZipDownload(downloadToken) {
    executeDownload('download/zip', downloadToken)
  }
  ,

  getZipDownloadLink(downloadToken) {
    return getUrl('download/zip', downloadToken)
  }
  ,

  getImagerPresetLinks(assetId) {
    return getData(store.state.main.portal + "/imager/api/getEncryptedPresetUrls/" + assetId)
  }
  ,
  getImagerOriginalUrls(assetId) {
    return getData(store.state.main.portal + "/imager/api/getEncryptedOriginalUrls/" + assetId)
  }
};