<template>
  <div id="app">
    <v-app>
      <router-view v-if="loaded"/>
    </v-app>
  </div>
</template>
<script>
  import remoteApi from "./services/remoteApi"
  import localApi from "./services/localApi"

  export default {
    data() {
      return {
        loaded: false
      }
    },
    methods: {
      baseInit() {
        let i18n = this.$i18n
        let requestedLocale = this.$i18n.locale
        remoteApi.loadBaseSettings(requestedLocale).then(response => {
          let effectiveLocale = response.locale
          i18n.locale = effectiveLocale
          localApi.setAuthenticationType(response.authenticationType)
          this.$store.commit("main/SET_EXTERNAL_AUTHENTICATION_URL", response.externalAuthenticationUrl)
          this.$store.commit("main/SET_HAS_LOGIN_TEXT", response.hasLoginText)
          console.log(response.hasLoginText)
          localApi.mergeLanguage(i18n, effectiveLocale, response.translations)
          localApi.applyTheme(this.$vuetify, response.portalColorTheme)
          this.loaded = true
        })
      }
    },
    created() {
      if (this.$store.state.main.portal === '') {
        remoteApi.getBackend().then(response => {
          try {
            localApi.initClient(response)
            this.baseInit()
          } catch (e) {
            // router.push({name: 'Maintenance'})
          }
        })
      } else {
        this.baseInit()
      }
    }
  }
</script>
<style>
</style>
