import remoteApi from '../services/remoteApi'
import Asset from "../model/Asset"
import {i18n} from '../i18n/i18n.js'
import SearchQuery from "../model/SearchQuery"

export const assets = {
  namespaced: true,

  state: {
    assets: [],
    totalAssets:0,
    page: 0,
    selectedAsset: undefined,
    pageSize:30,
    activeSidePanelIndex:0
  },

  mutations: {
    SET_ASSET_MODE: (state, mode) => {
      state.mode = mode
    },

    SET_SELECTED_ASSET: (state, asset) => {
      state.selectedAsset = asset
    },

    resetAssets: (state, assets) => {
      state.page = 0
      state.assets.items = [];
      state.assets = assets;
      state.totalAssets = 0
      state.selectedAsset = {}
    },

    pushAssets: (state, assets) => {
      //Prevent vue from adding reactive properties by freezing the asset before we add it -> performance
      assets.forEach(asset => state.assets.push(Object.freeze(asset)))
      // state.assets.push(...assets)
    },

    setPages: (state, pages) => {
      state.pages = pages
    },

    increasePage: (state) => {
      state.page++
    },

    RESET_PAGE: (state) => {
      state.assets = []
      state.page = 0
    },

    SET_TOTAL_ASSETS: (state, totalAssets) => {
      state.totalAssets = totalAssets
    }
  },

  actions: {
    assetSearch: ({rootState, state, commit}, pageSize= 32) => {
      if(state.page === 0){
        commit("SET_TOTAL_ASSETS",0)
      }

      return new Promise(resolve => {
        let searchValues = rootState.search.searchQuery.currentSearchQueryValues
        let searchQuery = new SearchQuery(state.page, pageSize, searchValues, i18n.locale)
        searchQuery.activeSidePanelIndex = state.activeSidePanelIndex
        remoteApi.assetSearch(searchQuery).then(data => {
          return processResult(resolve, data, commit)
        })
      })
    },
    loadSearchResult: async ({rootState}, pageSize= 32) => {
        let searchValues = rootState.search.searchQuery.currentSearchQueryValues
        let searchQuery = new SearchQuery(0, pageSize, searchValues, i18n.locale)
        let data = await remoteApi.assetSearch(searchQuery)
        return data.items.map(item => new Asset(item))
    },
    loadContainerAssets({dispatch}) {
      return dispatch('assetSearch')
      // return new Promise((resolve) => {
      //   let container = rootState.container.selectedContainer
      //   remoteApi.loadAssets(container, state.page, i18n.locale).then(data => {
      //     return processResult(resolve, data, commit)
      //   })
      // })
    },

    loadAssets: ({dispatch}, initSearch) => {
      return dispatch('assetSearch', initSearch)
    },

    loadAsset: ({commit}, assetId) => {
      return new Promise((resolve) => {
        remoteApi.loadAsset(assetId, i18n.locale).then(data => {
          commit("SET_SELECTED_ASSET", new Asset(data))
          resolve(true)
        }).catch(() => {
          resolve(false)
        })
      })
    }
  }
};

function processResult(resolve, data, commit) {
  if (data.items.length) {
    commit('increasePage')
    commit('pushAssets', data.items.map(item => new Asset(item)))
    commit('SET_TOTAL_ASSETS', data.numberTotal)
    let hasMoreItems = data.page + 1 === data.pages
    resolve({hasItems: true, hasMoreItems: hasMoreItems})
  } else {
    resolve({hasItems: false, hasMoreItems: false})
  }
}
