<template>
  <div>
    <v-layout>
      <asset-row :top-divider="false">

        <asset-row-column cols="1" class="pb-0 pt-0">
          <v-hover v-slot:default="{ hover }">
            <v-sheet class="pa-1" :color="borderAlertColor">
            <v-card tile class="largeThumbHover" :elevation="hover ? 12 : 2" :class="{ 'on-hover': hover }"
                    @click="$emit('showPreview', assetItem)" :width="thumbnailWidth">
              <asset-item-preview lazy-src="../../placeholder_thumbnail.png" :min-width="thumbnailWidth" :max-height="thumbnailHeight" :max-width="thumbnailWidth" :height="thumbnailHeight" :width="thumbnailWidth" :asset-item="assetItem" :class="{ 'show-btn': hover }" type="THUMBNAIL_URL" :contain="containImage">
                <v-container class="fill-height">
                  <v-spacer/>
                  <v-btn v-if="hover" icon>
                    <portal-icon class="list-show-detail-ie" v-if="assetItem.isVideo()" portal-icon-id="list.showVideoPreview"/>
                    <portal-icon class="list-show-detail-ie" v-else portal-icon-id="list.showImagePreview"/>
                  </v-btn>
                  <v-spacer/>
                </v-container>
              </asset-item-preview>
            </v-card>
            </v-sheet>
          </v-hover>
        </asset-row-column>
        <asset-row-column v-if="hasBullets" class="flex-column ma-0 fill-height pb-0 pt-0" container-class="pa-1">
          <v-col cols="1">
            <v-row class="pa-0" v-for="bullet in bullets.icons" :key="bullet.identifier">
              <portal-bullet :mdi-icon-name="bullet.properties['icon']"
                             :color="bullet.properties['color']"
                             :tooltip="tooltip(bullet)"
                             :key="bullet.identifier"/>
            </v-row>
          </v-col>
        </asset-row-column>
        <asset-row-column>
          <v-col cols="1">
            <span>&nbsp;</span>
          </v-col>
        </asset-row-column>
        <asset-row-column cols="5" class="asset-name pb-0 pt-0">
          <asset-item-property :asset-item="assetItem" name="ASSET_NAME"/>
        </asset-row-column>
        <asset-row-column cols="2" class="pb-0 pt-0">
          <asset-item-property :asset-item="assetItem" name="DATE_MODIFIED"/>
        </asset-row-column>
        <asset-row-column cols="2" class="d-none d-sm-flex pb-0 pt-0">
          <asset-item-property :asset-item="assetItem" name="ORIGINAL_FILE_SIZE"/>
        </asset-row-column>
        <v-spacer></v-spacer>
        <asset-row-column class="flex-column ma-0 fill-height pb-0 pt-0" container-class="pa-1">
          <v-col>
            <v-row class="pa-0">
              <icon-button portal-icon-id="list.actionButton.showDetailView" :top="true" :tooltip="$t('tooltipShowDetailView')"
                           @click="$emit('showDetail', assetItem)"/>
            </v-row>
            <v-row class="pa-0">
              <icon-button portal-icon-id="list.actionButton.downloadAsset" v-if="basketConfig.allowDirectDownload" :top="true"
                           :tooltip="$t('tooltipDownloadAsset')" @click="$emit('download',assetItem)"/>
            </v-row>
            <v-row class="pa-0">
              <template v-if="basketConfig.enableBasket">
                <icon-button portal-icon-id="list.actionButton.addToBasket" v-if="!inBasket(assetItem)" :top="true"
                             :tooltip="$t('tooltipAddToBasket')" @click="$emit('addBasket',assetItem)"/>
                <icon-button portal-icon-id="list.actionButton.removeFromBasket" v-else :top="true"
                             :tooltip="$t('tooltipRemoveFromBasket')" @click="$emit('removeBasket',assetItem)"/>
              </template>
            </v-row>
          </v-col>
        </asset-row-column>
      </asset-row>
    </v-layout>
  </div>
</template>

<script>
  import AssetItemPreview from "./AssetItemPreview"
  import AssetItemProperty from "./AssetItemProperty"
  import {mapGetters, mapState} from 'vuex'
  import AssetRow from "./AssetRow"
  import AssetRowColumn from "./AssetRowColumn"
  import IconButton from "./IconButton"
	import PortalIcon from "./PortalIcon";
  import PortalBullet from "./PortalBullet"

  export default {
    components: {PortalBullet, PortalIcon, IconButton, AssetItemProperty, AssetItemPreview, AssetRowColumn, AssetRow},
    props: ['assetItem'],
    methods:{
      tooltip(bullet){
        if(bullet.properties){
          if(bullet.properties['translateTooltip']){
            return this.$t(bullet.properties['tooltip'])
          }
          return bullet.properties['tooltip']
        }
        return ""
      }
    },
    computed: {
      ...mapState({
        basketConfig: state => state.config.server.basketConfiguration
      }),
      ...mapGetters({
        'inBasket': 'basket/inBasket',
        'containImage':'config/assetTileContainsImage'
      }),
      thumbnailHeight(){
        return 80
      },
      thumbnailWidth(){
        return 80
      },
      borderAlertColor() {
        return this.assetItem.entity.displayElements.TILE
            .filter(e => e.elementType === "ASSET_ITEM_TILE_BORDER")
            .flatMap(b => b.properties)
            .filter(b => b.color !== undefined)
            .map(b => b.color)[0] || ""
      },
      hasBullets(){
        return this.assetItem.entity.displayElements.TILE
        .filter(e => e.elementType === "ICON_GROUP").length !== 0
      },
      bullets(){
        let filterElement = this.assetItem.entity.displayElements.TILE
            .filter(e => e.elementType === "ICON_GROUP")[0]
        if(filterElement){
          return filterElement.properties
        }
        return {}
      },
      hasText(){
        return this.assetItem.entity.displayElements.TILE
            .filter(e => e.elementType === "ICON_GROUP").length !== 0
      }
    }
  }
</script>

<style scoped>
  .asset-name {
    font-size: medium;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0;
  }

  .largeThumbHover {
    opacity: 0.6;
    transition: .4s ease-in-out;
  }

  .largeThumbHover:not(.on-hover) {
    opacity: 1;
  }

  /*!* Hack for placing icons in IE11 centered *!*/
  /*_:-ms-fullscreen, :root .thumbnail-icon {*/
  /*  margin-left: -27px;*/
  /*}*/

  /*_:-ms-fullscreen,*/
  /*:root .list-show-detail-ie {*/
  /*  margin-left: -3px;*/
  /*}*/

</style>
